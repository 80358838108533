// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import { Menu } from 'semantic-ui-react';

const ContactURL = 'https://support.spidertracks.com/knowledge/kb-tickets/new';

export default class HelpLink extends React.Component {
  render() {
    return (
      <Menu.Item key="register" as="a" href={`${ContactURL}`}>
        Contact Us
      </Menu.Item>
    );
  }
}
